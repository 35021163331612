/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Function to determine if an element is currently in the viewport
  $.fn.isOnScreen = function() {
      var viewport = {};
      viewport.top = $(window).scrollTop();
      viewport.bottom = viewport.top + $(window).height();
      var bounds = {};
      bounds.top = this.offset().top;
      bounds.bottom = bounds.top + this.outerHeight();
      return ((bounds.top <= viewport.bottom) && (bounds.bottom >= viewport.top));
  };

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

      },
      finalize: function() {

        // ================== MAV ============================

        var mobileMenu = null;
          var timeouts = {};
          var menuOpen = false;
          function openMenu(obj) {
            menuOpen = true;
            $('nav ul .tabarrow').find('.dd').removeClass('hover');
            $('nav ul .tabarrow').find('a:first').removeClass('hover');
            $('.menu-tab-content').removeClass('active');
            $(obj).find('a:first').addClass('hover');
            $(obj).find('.dd').addClass('hover');
            $(obj).find('.menu-tab-content').parent().find('a').removeClass('active');
            $(obj).find('.menu-tab-content').first().addClass('active');
            $(obj).find('.menu-tab-content').first().parent().children('a').addClass('active');
          }
          function closeMenu(obj) {
            menuOpen = false;
            if (mobileMenu) {
              obj = '.navbar-nav';
            }
            $(obj).find('a:first').removeClass('hover');
            $(obj).find('.dd').removeClass('hover');
            $(obj).find('.menu-tab-content').find('a').removeClass('active');
            $(obj).find('.menu-tab-content').removeClass('active');
          }
          function openNav() {
            if (mobileMenu) {
              $('body').addClass('mobile-menu-open');
              $('section').addClass('blur');
            }
          }
          function closeNav() {
            if (mobileMenu) {
              $('body').removeClass('mobile-menu-open');
              $('section').removeClass('blur');
            }
          }
          function hoverStart() {
            var rel = $(this).attr("id");
            if (timeouts[rel]) {
              clearTimeout(timeouts[rel]);
            }
            openMenu(this);
          }
          function hoverEnd() {
            var rel = $(this).attr("id");
            var el = $(this);
            if (timeouts[rel]) {
              clearTimeout(timeouts[rel]);
            }
            timeouts[rel] = setTimeout(function() {
              closeMenu(el);
            }, 100);
          }
          function doClick(event) {
            if ($(event.target).hasClass('expand-close')) {
                //$(event.target).toggleClass('bg-orange');
                $(event.target).toggleClass('fa-angle-down');
                $(event.target).toggleClass('fa-angle-up');
                $(this).siblings(".main-menu-link").toggleClass('bg-orange');
                //$(this).siblings(".dd").css({'background':'white'}).toggle();
                $(this).siblings(".dd").toggle();
            } else if ($(event.target).hasClass('main-menu-link')) {
              if (menuOpen) {
                closeMenu(this);
              } else {
                openMenu(this);
              }
            }
          }
          function doNavClick() {
            if ($('.navbar-toggle').hasClass('collapsed')) {
              openNav();
            } else {
              closeNav();
            }
          }
          function doMenuResize() {
            if ((!mobileMenu || mobileMenu === null) && $(window).width() <= 1000) {
              mobileMenu = true;
              $('nav ul .tabarrow').off('hover');
              $('nav ul .tabarrow .expand-close').click(doClick);
              $('nav .navbar-toggle').click(doNavClick);
            } else if ((mobileMenu || mobileMenu === null) && $(window).width() > 1000) {
              mobileMenu = false;
              $('nav .navbar-toggle').off('click');
              $('nav ul .tabarrow').off('click');
              $('nav ul .tabarrow').hover(hoverStart, hoverEnd);
            }
          }
          // Toggle whether or not we are a moible menu.
          doMenuResize();
          $(window).on("resize", function(event) {
            doMenuResize();
          });

        // ================== BACK TO TOP ============================

        $(".back-to-top").click(function() {
          $('html, body').animate({
            scrollTop: 0
          }, 2000);
        });

        // ================== SCROLL ============================

        // Parallax, Sticky Anchor, and Animations
        $(window).scroll(function() {
          var scrolledY = $(window).scrollTop();

        /* OLD
        var li = 1;
        while ($('.layout-' + li).length > 0) {
            var layout = $('.layout-' + li);
            if (layout.isOnScreen(1, 1)) {
                var layout_right = layout.hasClass('image-content-right');
                var layout_left = layout.hasClass('image-content-left');

                var layout_img = layout.find('img')

                if (layout_right) {
                    if (!layout_img.hasClass('animated slideInRight')) {
                        window.setTimeout(function(){
                            layout_img.addClass('animated slideInRight');
                        }, 150);

                    }
                }

                if (layout_left) {
                    if (!layout_img.hasClass('animated slideInLeft')) {
                        window.setTimeout(function(){
                            layout_img.addClass('animated slideInLeft');
                        }, 150);
                    }
                }
            }

            li++;
        }
        */

// ================== NEW ======================


            var li = 1;
            while ($('.layout-' + li).length > 0) {
              var layout = $('.layout-' + li);
              var content = layout.find('.content');

              if (layout.isOnScreen(1, 1)) {
                var layout_right = layout.hasClass('image-content-right');
                var layout_left = layout.hasClass('image-content-left');
                if (layout_left || layout_right) {
                  var _class = layout_left ? 'animated slideInLeft' : 'animated slideInRight';
                  var o_class = layout_left ? 'animated slideInRight' : 'animated slideInLeft';
                  var layout_img = layout.find('.wrap');
                  if (!layout_img.hasClass(_class)) {
                    window.setTimeout(function() {
                      layout_img.addClass(_class);
                      if (content) {
                        //content.addClass(o_class);
                      }
                    }, 200);
                  }
                }
              }
              li++;
            }

          window.requestAnimationFrame(function() {

            if ($('.section-1').length > 0) {
              if ($('.section-1').isOnScreen(1, 1)) {
                //$('.section-1>.parallax').css('transform', 'translate3d(0, ' + scrolledY + 'px, -15px)');
              }
            }
            if ($('.section-2').length > 0) {
              if ($('.section-2').isOnScreen(1, 1)) {
                var topOfElement = ($('.section-2').offset().top);
                var bottomOfPage = $(window).scrollTop() + $(window).height();
                var diff = (topOfElement-bottomOfPage) / 12;
                //$('.section-2>.parallax').css('transform', 'translate3d(0, ' + diff + 'px, -15px)');
              }
            }
            if ($('.section-4').length > 0) {
              if ($('.section-4').isOnScreen(1, 1)) {
                var topOfElement_4 = ($('.section-4').offset().top);
                var scrollY = $(window).scrollTop();
                var diff_4 = scrollY - topOfElement_4;
                //$('.section-4>.parallax').css('transform', 'translate3d(0, ' + diff_4 + 'px, -15px)');

                if (!$('.section-4').hasClass('odometer-init')) {
                    if (!$('.section-4').hasClass('odometer-init-1')) {
                        $('.section-4').addClass('odometer-init-1');
                        var to1 = window.setTimeout(function(){
                            $('.treatment-count-num-1').html(Number($('#count1').attr('data-count')));
                        }, 1000);
                    }

                    if (!$('.section-4').hasClass('odometer-init-2')) {
                        $('.section-4').addClass('odometer-init-2');
                        var to2 = window.setTimeout(function(){
                            $('.treatment-count-num-2').html(Number($('#count2').attr('data-count')));
                        }, 1000);
                    }

                    if (!$('.section-4').hasClass('odometer-init-3')) {
                        $('.section-4').addClass('odometer-init-3');
                        var to3 = window.setTimeout(function(){
                            $('.treatment-count-num-3').html(Number($('#count3').attr('data-count')));
                        }, 1000);
                    }
                   $('.section-4').addClass('odometer-init');
                }

              }
            }

            if ($('.contact-us').length > 0) {
              if ($('.contact-us').isOnScreen(1, 1)) {
                var topOfElement_c = ($('.contact-us').offset().top);
                var scrollY = $(window).scrollTop();
                var diff_c = scrollY - topOfElement_c;
                //$('.contact-us>.parallax').css('transform', 'translate3d(0, ' + diff_c + 'px, -15px)');
              }
            }
          });

          if ($('div.sticky-anchor').length) {
              var bottom = $('div.sticky-anchor').offset().top;
              if ($(this).scrollTop() > bottom){
                  $('.sticky-anchor>nav').addClass('navbar-fixed-top');
              } else {
                  $('.sticky-anchor>nav').removeClass('navbar-fixed-top');
              }
          }

          if ($('.hero-bottom').length > 0) {
            if ($('.hero-bottom .trigger-animation').isOnScreen(1, 1)) {
              setTimeout(function() {
                $('.hero-bottom .btn-hero').eq(0).addClass('animated slideInLeft');
                $('.hero-bottom .btn-hero').eq(1).addClass('animated slideInRight');
              }, 500);
            } else {
              if ($('.hero-bottom .btn-hero').eq(0).hasClass('animated')) {
                $('.hero-bottom .btn-hero').removeClass('animated slideInLeft slideInRight');
              }
            }
          }
        });

      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        setTimeout(function() {
          $('section.hero span.one').addClass('animated fadeInDown');
          setTimeout(function() {
            $('section.hero span.two').addClass('animated fadeInUp');
            setTimeout(function() {
              $('section.hero .subheading .line1').addClass('animated slideInLeft');
              setTimeout(function() {
                $('section.hero .subheading .line2').addClass('animated slideInLeft');
                setTimeout(function() {
                  $('section.hero .btn-hero').addClass('animated fadeInDown');
                  setTimeout(function() {
                    $('section.hero .arrow').addClass('animated fadeInUp');
                  }, 500);
                }, 500);
              }, 250);
            }, 500);
          }, 500);
        }, 500);
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // JavaScript for the collapsible categories, let's initialize everything!
  $('.widget_categories_extended ul.children').hide();
  $('.widget_categories_extended .current-cat-parent>ul').show();
  $('.widget_categories_extended .current-cat-parent span.expand>i').removeClass('bt-plus').addClass('bt-minus');
  $('.widget_nav_menu_extended ul.sub-menu').hide();
  $('.widget_nav_menu_extended .current-menu-parent>ul').show();
  $('.widget_nav_menu_extended .current-menu-item>ul').show();
  $('.widget_nav_menu_extended .current-menu-parent span.expand>i').removeClass('bt-plus').addClass('bt-minus');
  $('.widget_nav_menu_extended .current-menu-item span.expand>i').removeClass('bt-plus').addClass('bt-minus');

  // Handle category collapsing
  $('.widget_categories_extended span.expand').on('click', function() {
    if ($(this).find('i').hasClass('bt-plus')) {
      $(this).parent().parent().find('ul.children').hide();
      $(this).parent().parent().find('.bt-minus').removeClass('bt-minus').addClass('bt-plus');
      $(this).find('i').removeClass('bt-plus').addClass('bt-minus');
      $(this).parent().find('ul').show();
    } else {
      $(this).find('i').removeClass('bt-minus').addClass('bt-plus');
      $(this).parent().find('ul').hide();
    }
  });

  // Handle custom menu collapsing
  $('.widget_nav_menu_extended span.expand').on('click', function() {
    if ($(this).find('i').hasClass('bt-plus')) {
      $(this).parent().parent().find('ul.children').hide();
      $(this).parent().parent().find('.bt-minus').removeClass('bt-minus').addClass('bt-plus');
      $(this).find('i').removeClass('bt-plus').addClass('bt-minus');
      $(this).parent().find('ul').show();
    } else {
      $(this).find('i').removeClass('bt-minus').addClass('bt-plus');
      $(this).parent().find('ul').hide();
    }
  });



  // Load Events
  $(document).ready(UTIL.loadEvents);


})(jQuery); // Fully reference jQuery after this point.
